<template>
    <fade-up-animation :element="() => $refs.section">
        <div class="user-location-section" ref="section">
            <b-container>
                <mobile-gutter>
                    <user-location-form
                        :label="label"
                        :button-text="buttonText"
                        :restaurants="restaurants"
                        @success="handleSuccess"
                    />
                </mobile-gutter>
            </b-container>
        </div>
    </fade-up-animation>
</template>

<script>
    import UserLocationForm from '../../molecules/UserLocationForm/UserLocationForm';
    import { mapActions } from 'vuex';

    export default {
        name: 'user-location-section',

        components: { UserLocationForm },

        props: {
            label: {
                type: String,
                required: true,
            },

            buttonText: {
                type: String,
                required: true,
            },

            redirect: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                restaurants: [],
            };
        },

        /**
         * Fetch the restaurants from the api.
         */
        async fetch() {
            const { restaurants } = await this.getRestaurants();

            this.restaurants = (restaurants.data || []).map(({ id, attributes }) => ({
                id,
                name: attributes.name,
                address: attributes.address,
                lat: attributes.location_lat,
                lng: attributes.location_lng,
            }));
        },

        methods: {
            ...mapActions('restaurants', [
                'getRestaurants',
            ]),

            /**
             * Handle the user location success event.
             *
             * We'll redirect to the supplied redirect page, and this will feature the map.
             */
            handleSuccess() {
                if (!this.redirect || this.$route.path === this.redirect) {
                    // If no redirect provided or already on the redirect page do not perform the redirect
                    return;
                }

                this.$router.push(this.redirect);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .user-location-section {
        padding: 30px 0;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(lg) {
            padding: 40px 0;
        }
    }

    .user-location-form {
        margin: 0 auto;
        max-width: 645px;
    }
</style>
