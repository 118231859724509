<template>
    <div class="user-location-form">
        <form
            @submit.prevent="handleSubmit"
            v-test:form
        >
            <div class="inner">
                <text-input
                    id="address"
                    :label="label"
                    placeholder="enter town or postcode"
                    name="address"
                    :disabled="states.loading"
                    :errors="states.error"
                    autocomplete="postal-code"
                    @focus="states.focused = true"
                    @blur="states.focused = false"
                    v-model="formData.location"
                >
                    <template #button>
                        <b-button
                            class="submit-button"
                            variant="primary"
                            :disabled="states.loading"
                            type="submit"
                            v-test:submitButton
                        >
                            {{ buttonText }}
                        </b-button>
                    </template>
                </text-input>

                <transition name="dropdown" mode="out-in">
                    <div
                        v-if="states.focused && formData.location.length >= 3 && filteredRestaurants.length"
                        class="dropdown"
                    >
                        <ul>
                            <li
                                v-for="restaurant in filteredRestaurants"
                                :key="restaurant.id"
                            >
                                <b-button
                                    class="restaurant-button"
                                    variant="link"
                                    @click.prevent="goToRestaurant(restaurant)"
                                >
                                    {{ restaurant.name }}, <small>{{ restaurant.address }}</small>
                                </b-button>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
        </form>
    </div>
</template>

<script>
    import TextInput from '../../atoms/inputs/TextInput/TextInput';
    import { Loader } from '@googlemaps/js-api-loader';
    import { mapMutations } from 'vuex';

    export default {
        name: 'user-location-form',

        components: { TextInput },

        props: {
            label: {
                type: String,
                required: true,
            },

            buttonText: {
                type: String,
                required: true,
            },

            restaurants: {
                type: Array,
                default: () => ([]),
            },
        },

        data() {
            return {
                formData: {
                    location: this.$store.state.user.location || '',
                },
                states: {
                    loading: false,
                    error: [],
                    focused: false,
                },
            };
        },

        computed: {
            /**
             * Filter the restaurants to only show those which match the
             * input with the address or name of the restaurant.
             *
             * @returns {*[]}
             */
            filteredRestaurants() {
                return this.restaurants.filter(({ name, address }) => {
                    return name.toLowerCase().includes(this.formData.location.toLowerCase())
                        || address.toLowerCase().includes(this.formData.location.toLowerCase());
                }).sort((a, b) => {
                    // Sort the results so that matches at the start of the name/address are prioritised
                    return (
                        b.name.toLowerCase().indexOf(this.formData.location.toLowerCase())
                        ?? b.address.toLowerCase().indexOf(this.formData.location.toLowerCase())
                    ) - (
                        a.name.toLowerCase().indexOf(this.formData.location.toLowerCase())
                        ?? a.address.toLowerCase().indexOf(this.formData.location.toLowerCase())
                    );
                });
            },
        },

        methods: {
            ...mapMutations('user', ['setLocation']),

            /**
             * Load the google api.
             */
            async loadGoogleScript() {
                if (this.google) {
                    return;
                }

                if (window.google) {
                    this.google = window.google;
                    return;
                }

                const loader = new Loader({
                    apiKey: this.$config.GOOGLE_API_KEY,
                    version: 'weekly',
                    libraries: ['places'],
                });

                this.google = await loader.load();
            },

            /**
             * Handle the form submission.
             */
            async handleSubmit() {
                this.states.error = [];
                this.states.loading = true;

                try {
                    await this.loadGoogleScript();

                    let { location, retry } = await this.lookupLocation();

                    if (retry) {
                        ({ location, retry } = await this.lookupLocation(true));
                    }

                    this.setLocation(location);

                    this.$emit('success');
                } catch (e) {
                    this.states.error = ['We could not find your location'];
                } finally {
                    this.states.loading = false;
                }
            },

            /**
             * Lookup the provided location via google geocoder.
             *
             * @param {boolean?} addPrefix
             *
             * @returns {Promise<{lat: string|number, lng: string|number, location: string}>}
             */
            lookupLocation(addPrefix) {
                const { Geocoder } = this.google.maps;

                return new Promise((resolve, reject) => {
                    (new Geocoder).geocode({
                        address: addPrefix ? `${ this.formData.location }, uk` : this.formData.location,
                    }, (results, status) => {
                        if (status === 'OK') {
                            const [{
                                geometry,
                                formatted_address,
                                partial_match,
                            }] = results;

                            if (partial_match) {
                                return reject('We could not find your location');
                            }

                            return resolve({
                                location: {
                                    location: formatted_address,
                                    lat: geometry.location.lat(),
                                    lng: geometry.location.lng(),
                                },
                            });
                        }

                        if (status === 'ZERO_RESULTS' && !addPrefix) {
                            resolve({
                                retry: true,
                            });
                        }

                        reject(status);
                    });
                });
            },

            /**
             * Got to a restaurant on the map.
             *
             * @param {object} restaurant
             */
            goToRestaurant(restaurant) {
                this.formData.location = `${ restaurant.name }, ${ restaurant.address.replace(/\n/g, ', ') }`;

                this.$nuxt.$emit('goToRestaurant', restaurant);
            },
        },
    };
</script>

<style lang="scss" scoped>
    form {
        border-radius: 141px;
        background-color: secondary-palette(7);
        padding: 24px;

        @include media-breakpoint-up(md) {
            padding: 24px 64px;
        }
    }

    .inner {
        position: relative;
    }

    .text-input.text-input {
        margin: 0;

        ::v-deep .form-control {
            border-radius: 20px;
            padding-left: rem(19px);
            padding-right: rem(19px);

            &:not(:focus):not(.is-invalid) {
                border-color: #ffffff;
            }
        }

        ::v-deep .label.label {
            font-size: rem(24px);
            text-align: center;
            display: block;
            font-weight: bold;
            color: primary-palette(2);
            line-height: inherit;
            margin-bottom: rem(8px);

            @include media-breakpoint-up(md) {
                text-align: left;
            }
        }
    }

    .submit-button {
        border-radius: 0 20px 20px 0;

        @include media-breakpoint-up(md) {
            min-width: 135px;
        }
    }

    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        margin-top: 10px;
        max-height: 200px;
        overflow: auto;
        z-index: $zindex-dropdown;
        padding: rem(9.5px) 0;
    }

    ul {
        margin: 0;
        list-style: none;
        padding: 0;
    }

    .restaurant-button {
        padding: 5px rem(19px);
        display: block;
        width: 100%;
        text-align: left;
    }

    .dropdown-enter-active,
    .dropdown-leave-active {
        transition: 0.3s;
    }

    .dropdown-enter,
    .dropdown-leave-to {
        opacity: 0;
        transform: translateY(5px);
    }
</style>
