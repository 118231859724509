<template>
    <horizontal-image-text-cta
        class="horizontal-image-cta-section-module"
        :image="image"
        :title="attributes.title"
        :description="attributes.description"
        :button="button"
        :secondary-button="secondaryButton"
        :reverse="attributes.reverse"
        :image-first-on-mobile="attributes.image_first_on_mobile"
    />
</template>

<script>
    import HorizontalImageTextCta from '../../../molecules/HorizontalImageTextCta/HorizontalImageTextCta';
    import formatMediaObject from '../../../../utils/formatters/format-media-object';

    export default {
        name: 'horizontal-image-cta-section-module',

        components: { HorizontalImageTextCta },

        props: {
            attributes: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },

        computed: {
            /**
             * Get cta image.
             *
             * @returns {object}
             */
            image() {
                return formatMediaObject.call(this, this.attributes.image);
            },

            /**
             * Get button.
             *
             * @returns {object}
             */
            button() {
                return {
                    name: this.attributes.button_text,
                    to: this.attributes.button_link
                        ?.data
                        ?.attributes
                        ?.uri,
                    href: this.attributes.button_link_external,
                };
            },

            /**
             * Get the secondary button details.
             *
             * @returns {object}
             */
            secondaryButton() {
                return {
                    name: this.attributes.button_2_text,
                    to: this.attributes.button_2_link
                        ?.data
                        ?.attributes
                        ?.uri,
                    href: this.attributes.button_2_link_external,
                };
            },
        },
    };
</script>
